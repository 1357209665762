var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 bg-uni",attrs:{"fluid":"","fill-width":"","fill-height":""}},[_c('div',{staticClass:"gray-scale"}),_c('v-container',{staticClass:"align-content-center",attrs:{"fill-height":""}},[_c('v-img',{staticClass:"mx-auto position-absolute top-30 centered",attrs:{"width":"900px","contain":"","height":"650px","src":require("@/assets/img/logoNew.png")}}),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"mx-auto pt-10 header-text"},[_c('h1',{staticClass:"text-h4 text-md-h2 text-center white--text main-text"},[_vm._v(" "+_vm._s(_vm.$_t("dashboard.simple_way"))+" ")])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"#4340DA","to":{
              name: _vm.user
                ? _vm.user.role === 'Student'
                  ? 'report.main'
                  : 'admin.users'
                : 'auth.login',
            },"dark":"","width":"256px","height":"50px"}},[_vm._v(_vm._s(_vm.$_t("dashboard.get_started")))])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }