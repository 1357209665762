<template>
  <v-container fluid fill-width fill-height class="pa-0 bg-uni">
    <div class="gray-scale"></div>
    <v-container fill-height class="align-content-center">
      <v-img
        class="mx-auto position-absolute top-30 centered"
        width="900px"
        contain
        height="650px"
        src="@/assets/img/logoNew.png"
      ></v-img>
      <v-row class="justify-center">
        <v-col cols="12" md="8">
          <div class="mx-auto pt-10 header-text">
            <h1 class="text-h4 text-md-h2 text-center white--text main-text">
              {{ $_t("dashboard.simple_way") }}
            </h1>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <v-btn
              color="#4340DA"
              :to="{
                name: user
                  ? user.role === 'Student'
                    ? 'report.main'
                    : 'admin.users'
                  : 'auth.login',
              }"
              dark
              width="256px"
              height="50px"
              >{{ $_t("dashboard.get_started") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "upcoming",
  data: () => ({}),
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
      user: "authentication/user",
    }),
  },
};
</script>
<style scoped>
.bg-uni {
  background: url("../../../../src/assets/img/uni2.jpg") center no-repeat !important;
  background-size: cover !important;
  position: relative;
}
.gray-scale {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.47);
}
.header-text {
  position: relative;
  z-index: 99;
}
.main-text {
  font-style: normal;
  font-weight: 500;
  line-height: 5rem;
  text-align: center;
}
.position-absolute {
  position: absolute !important;
}
.top-30 {
  top: 30px;
}
.centered {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
